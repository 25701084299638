import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const API = `${process.env.REACT_APP_API_BASE_URL}/passport`;

export const getFxData = async (authCode) => {
  const { data } = await axios.get(`${API}/onboarding/fx/${authCode}`);

  return data;
};

export const useOnboardingPassport = (email, { enabled }) =>
  useQuery({
    queryKey: ['onboardingPassport'],
    queryFn: async () => {
      const { data } = await axios.get(`${API}/onboarding/${email}`);

      return data;
    },
    enabled
  });

export const useOnboardingPassportDocuments = (authCode, { enabled }) =>
  useQuery({
    queryKey: ['useOnboardingPassportDocuments'],
    queryFn: async () => {
      try {
        const { data } = await axios.get(`${API}/onboarding/documents/${authCode}`);

        return data;
      } catch (e) {
        return e.response.data;
      }
    },
    enabled
  });

export const usePassportRequests = () =>
  useQuery({
    queryKey: ['passportRequests'],
    queryFn: async () => {
      const { data } = await axios.get(`${API}/requests`);

      return data;
    }
  });

export const usePassportOffers = () =>
  useQuery({
    queryKey: ['passportOffers'],
    queryFn: async () => {
      const { data } = await axios.get(`${API}/offers`);

      return data;
    }
  });

export const savePassportRequest = async (data) => {
  const resp = await axios.post(`${API}/request`, data);

  return resp.data;
};

export const savePassportOnboardingsDocuments = async (authCode, data) => {
  const resp = await axios.post(`${API}/onboarding/documents/${authCode}`, data);

  return resp.data;
};

export const submitPassportOnboardingsDocuments = async (authCode) => {
  const resp = await axios.post(`${API}/onboarding/documents/${authCode}/submit`, {});

  return resp.data;
};

export const getPassportRequest = async (email) => {
  const { data } = await axios.get(`${API}/onboarding/${email}`);

  return data;
};

export const createPutPresignedUrl = async (fileExtension) => {
  const resp = await axios.get(`${API}/documents/put-presigned-url?fileExtension=${fileExtension}`);

  return resp.data;
};

export const createGetPresignedUrl = async (key) => {
  const resp = await axios.get(`${API}/documents/get-presigned-url?key=${key}`);

  return resp.data;
};

export const createOnboardingPutPresignedUrl = (authCode) => async (fileExtension) => {
  const resp = await axios.get(
    `${API}/onboarding/documents/${authCode}/put-presigned-url?fileExtension=${fileExtension}`
  );

  return resp.data;
};

export const createOnboardingGetPresignedUrl = (authCode) => async (key) => {
  const resp = await axios.get(
    `${API}/onboarding/documents/${authCode}/get-presigned-url?key=${key}`
  );

  return resp.data;
};

export const requestPassportOffer = async (data) => await axios.post(`${API}/offers/request`, data);

export const createPassportOnboardingRequest = async (data) =>
  await axios.post(`${API}/onboarding`, data);

export const transferOnboardingRequestToAccount = async (data) =>
  await axios.post(`${API}/onboarding/account`, data);
