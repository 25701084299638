import React, { lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Loadable from '@ui/components/Loadable';
import { ProtectedRoute } from './components/ProtectedRoute';
import { useTracking } from '@ui/hooks/useTracking';
import { ReferralContextProvider } from './hooks/useReferral';
import { PassportContextProvider } from './hooks/usePassport';

const Register = Loadable(lazy(() => import('./pages/account/Register')));
const Login = Loadable(lazy(() => import('./pages/account/Login')));
const PasswordRecover = Loadable(lazy(() => import('./pages/account/PasswordRecover')));
const PasswordRecoverConfirm = Loadable(
  lazy(() => import('./pages/account/PasswordRecoverConfirm'))
);
const RegisterConfirm = Loadable(lazy(() => import('./pages/account/RegisterConfirm')));
const MoreRegisterConfirmOptions = Loadable(
  lazy(() => import('./pages/account/MoreRegisterConfirmOptions'))
);
const RegisterChangeEmailConfirmOptions = Loadable(
  lazy(() => import('./pages/account/RegisterChangeEmailConfirmOptions'))
);
const RegisterChangePhoneConfirmOptions = Loadable(
  lazy(() => import('./pages/account/RegisterChangePhoneConfirmOptions'))
);

const Home = Loadable(lazy(() => import('./pages/home/Home')));
const Referrals = Loadable(lazy(() => import('./pages/referrals/Referrals')));
const ReferralsWithdrawal = Loadable(lazy(() => import('./pages/referrals/ReferralsWithdrawal')));
const FinancePassportTMLandingPageView = Loadable(
  lazy(() => import('./pages/passport/FinancePassportTMLandingPageView'))
);
const PassportOnboardingLandingPageView = Loadable(
  lazy(() => import('./pages/passport/PassportOnboardingLandingPageView'))
);
const PassportOnboardingDetails = Loadable(
  lazy(() => import('./pages/passport/PassportOnboardingDetails'))
);
const PassportOnboardingResume = Loadable(
  lazy(() => import('./pages/passport/PassportOnboardingResume'))
);
const PassportOnboardingDocumentsManagement = Loadable(
  lazy(() => import('./pages/passport/PassportOnboardingDocumentsManagement'))
);
const PassportOnboardingThankYouPage = Loadable(
  lazy(() => import('./pages/passport/PassportOnboardingThankYouPage'))
);
const Passport = Loadable(lazy(() => import('./pages/passport/Passport')));
const PassportRequestView = Loadable(lazy(() => import('./pages/passport/PassportRequestView')));
const PassportDetails = Loadable(lazy(() => import('./pages/passport/PassportDetails')));
const PassportRequest = Loadable(lazy(() => import('./pages/passport/PassportRequest')));
const PassportDocuments = Loadable(lazy(() => import('./pages/passport/PassportDocuments')));
const Notifications = Loadable(lazy(() => import('./pages/notifications/Notifications')));
const Settings = Loadable(lazy(() => import('./pages/settings/Settings')));
const SettingsRemove = Loadable(lazy(() => import('./pages/settings/RemoveAccount')));
const SettingsPersonalInformation = Loadable(
  lazy(() => import('./pages/settings/PersonalInformation'))
);
const SettingsBankDetails = Loadable(lazy(() => import('./pages/settings/BankDetails')));
const SettingsBankDetailsSubmit = Loadable(
  lazy(() => import('./pages/settings/BankDetailsSubmit'))
);
const SettingsHelp = Loadable(lazy(() => import('./pages/settings/Help')));
const ProfileSetupRegisterIntroFlow = Loadable(
  lazy(() => import('./pages/profile/RegisterFlows/OnboardingFlow'))
);
const CurrencyExchange = Loadable(lazy(() => import('./pages/currencies/CurrencyConverter')));
const CurrencyConverterOnboarding = Loadable(
  lazy(() => import('./pages/currencies/CurrencyConverterOnboarding'))
);

function RoutesComponent() {
  const location = useLocation();
  const { trackPage } = useTracking();

  useEffect(() => {
    trackPage({
      data: location?.pathname
    });
  }, [location, trackPage]);

  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route path="/register/:email" element={<Register />} />
      <Route path="/register/confirm" element={<RegisterConfirm />} />
      <Route path="/register/confirm/:email" element={<RegisterConfirm />} />
      <Route path="/register/confirm/:email/:code" element={<RegisterConfirm />} />
      <Route
        path="/register/confirm/more-options/:email"
        element={<MoreRegisterConfirmOptions />}
      />
      <Route
        path="/register/confirm/more-options/:email/email"
        element={<RegisterChangeEmailConfirmOptions />}
      />
      <Route
        path="/register/confirm/more-options/:email/phone"
        element={<RegisterChangePhoneConfirmOptions />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/login/:email" element={<Login />} />
      <Route path="/password/recover" element={<PasswordRecover />} />
      <Route path="/password/recover/:email" element={<PasswordRecover />} />
      <Route path="/password/recover/confirm/:email" element={<PasswordRecoverConfirm />} />
      <Route path="/password/recover/confirm/:email/:code" element={<PasswordRecoverConfirm />} />

      <Route path="/finance-passport-tm" element={<FinancePassportTMLandingPageView />} />
      <Route
        path="/finance-passport-tm/:referralCode"
        element={<FinancePassportTMLandingPageView />}
      />
      <Route
        path="/onboarding/passport/:countryCode"
        element={<PassportOnboardingLandingPageView />}
      />
      <Route
        path="/onboarding/passport/:countryCode/stage-2"
        element={<PassportOnboardingDetails />}
      />
      <Route path="/onboarding/passport/resume" element={<PassportOnboardingResume />} />
      <Route
        path="/onboarding/passport/documents/:authCode"
        element={<PassportOnboardingDocumentsManagement />}
      />
      <Route
        path="/onboarding/passport/:countryCode/thank-you/:email"
        element={<PassportOnboardingThankYouPage />}
      />

      <Route
        path="/"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <Home />
          </ProtectedRoute>
        }
      />

      <Route
        path="/passport"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <PassportContextProvider>
              <Passport />
            </PassportContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport/:country_code"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <PassportContextProvider>
              <PassportRequestView />
            </PassportContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport/details"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <PassportDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport/request"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <PassportContextProvider>
              <PassportRequest />
            </PassportContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport/request/:country_code"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <PassportContextProvider>
              <PassportRequest />
            </PassportContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport/request/update"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <PassportContextProvider>
              <PassportRequest updatePassportMode />
            </PassportContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport/documents/:authCode"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <PassportContextProvider>
              <PassportDocuments />
            </PassportContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <Notifications />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/remove"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SettingsRemove />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/personal-information"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SettingsPersonalInformation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/bank-details"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SettingsBankDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/bank-details/submit"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SettingsBankDetailsSubmit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/help"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SettingsHelp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile/intro"
        element={
          <ProtectedRoute>
            <ProfileSetupRegisterIntroFlow />
          </ProtectedRoute>
        }
      />
      <Route
        path="/referrals"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <ReferralContextProvider>
              <Referrals />
            </ReferralContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/referrals/withdrawal"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <ReferralContextProvider>
              <ReferralsWithdrawal />
            </ReferralContextProvider>
          </ProtectedRoute>
        }
      />
      <Route path="/r/:refId" element={<Register />} />
      <Route
        path="/p/:referralCode/:partnerPeferralCode"
        element={<FinancePassportTMLandingPageView />}
      />
      <Route
        path="/fx"
        element={
          <ProtectedRoute>
            <PassportContextProvider>
              <CurrencyExchange />
            </PassportContextProvider>
          </ProtectedRoute>
        }
      />
      <Route path="/onboarding/fx/:authCode" element={<CurrencyConverterOnboarding />} />
    </Routes>
  );
}

export default RoutesComponent;
